<template>
  <svg viewBox="0 0 343 312" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M119.24 36.646C121.106 30.7982 118.821 24.8536 114.136 23.3683C109.452 21.883 104.143 25.4195 102.277 31.2672C100.412 37.115 102.697 43.0596 107.381 44.5449C112.065 46.0302 117.375 42.4937 119.24 36.646Z"
      fill="#089DD9"
    />
    <path
      d="M235.663 44.5343C240.347 43.049 242.632 37.1043 240.767 31.2566C238.901 25.4088 233.592 21.8724 228.908 23.3577C224.224 24.843 221.939 30.7876 223.804 36.6353C225.67 42.4831 230.979 46.0196 235.663 44.5343Z"
      fill="#089DD9"
    />
    <path
      d="M249.776 131.06L242.231 71.8699L242.196 71.3492C249.748 75.8595 258.048 80.8694 265.67 84.8309V77.5623L171.5 0L77.3303 77.5623V84.8309C84.9384 80.8694 93.2311 76.0565 100.776 71.7362L100.769 71.8699L93.224 131.06C45.4369 134.902 0 140.074 0 140.074V143.557C14.0094 142.065 122.605 133.53 171.5 133.53C220.395 133.53 328.991 142.072 343 143.557V140.074C343 140.074 297.563 134.902 249.776 131.06ZM239.627 130.265C235.901 129.984 232.174 129.709 228.476 129.456L231.398 95.0407L239.627 130.265ZM111.595 95.0407L114.517 129.456C110.819 129.716 107.099 129.984 103.366 130.265L111.595 95.0407ZM124.743 128.78L123.699 57.593C137.242 44.9275 162.304 18.555 171.5 8.44369C180.696 18.555 205.772 44.3997 219.315 56.9245L218.257 128.773C200.958 127.69 184.648 126.979 171.5 126.979C158.352 126.979 142.042 127.69 124.743 128.78Z"
      fill="#089DD9"
    />
    <path
      d="M84.4443 187.007L79.3346 167.361H79.257L74.1473 187.007H70.5267L62.6363 158.889H66.9062L72.277 180.055H72.3547L77.8031 158.889H80.8167L86.1876 179.9H86.2652L91.756 158.889H95.9483L88.0578 187.007H84.4443Z"
      fill="#089DD9"
    />
    <path
      d="M114.552 187.007V183.742H114.474C112.95 186.324 110.169 187.541 107.311 187.541C100.183 187.541 96.1035 182.109 96.1035 176.558C96.1035 171.428 99.7241 165.384 107.311 165.384C110.247 165.384 112.95 166.601 114.474 168.916H114.552V165.954H118.518V187.007H114.552ZM107.311 168.993C102.434 168.993 100.07 173.208 100.07 176.515C100.07 180.315 103.083 183.925 107.276 183.925C111.433 183.925 114.63 180.54 114.63 176.515C114.63 172.033 111.39 168.993 107.311 168.993Z"
      fill="#089DD9"
    />
    <path
      d="M131.935 171.541C131.709 169.831 130.679 168.993 129.232 168.993C127.785 168.993 126.755 169.908 126.755 171.238C126.755 172.983 128.279 173.44 130.029 173.975C131.667 174.468 136.395 175.798 136.395 180.815C136.395 184.537 133.805 187.541 129.31 187.541C126.642 187.541 121.843 185.867 121.843 180.097H125.809C125.964 182.454 127.178 183.932 129.394 183.932C131.187 183.932 132.443 182.827 132.443 181.047C132.443 179.182 130.806 178.542 128.745 177.817C126.458 176.98 122.802 175.952 122.802 171.47C122.802 168.353 125.435 165.391 129.437 165.391C131.836 165.391 135.803 166.875 135.916 171.548H131.935V171.541Z"
      fill="#089DD9"
    />
    <path
      d="M154.533 187.007V175.572C154.533 173.94 154.534 169 149.276 169C145.959 169 143.714 171.315 143.714 175.537V187.014H139.748V158.896H143.714V168.205H143.792C145.697 165.391 148.859 165.391 149.699 165.391C152.974 165.391 155.642 166.798 157.166 169.155C158.006 170.52 158.465 172.195 158.5 174.552V187.014H154.533V187.007Z"
      fill="#089DD9"
    />
    <path
      d="M167.449 163.519H163.483V158.882H167.449V163.519ZM167.449 187.007H163.483V165.954H167.449V187.007Z"
      fill="#089DD9"
    />
    <path
      d="M187.309 187.007V175.572C187.309 173.94 187.309 169 182.051 169C178.734 169 176.49 171.315 176.49 175.537V187.014H172.523V165.961H176.222V168.205H176.299C178.431 165.391 181.635 165.391 182.475 165.391C185.749 165.391 188.417 166.798 189.942 169.155C190.781 170.52 191.24 172.195 191.275 174.552V187.014H187.309V187.007Z"
      fill="#089DD9"
    />
    <path
      d="M217.833 184.157C217.833 191.074 213.606 195.288 206.4 195.288C201.481 195.288 197.747 192.629 195.997 188.639H200.415C201.022 189.476 202.666 191.679 206.435 191.679C210.627 191.644 213.867 189.702 213.867 184.234H213.789C213.062 185.219 210.966 187.541 206.513 187.541C199.54 187.541 195.235 181.539 195.235 176.41C195.235 171.738 198.933 165.391 206.59 165.391C209.717 165.391 212.462 166.798 213.867 168.395V165.961H217.833V184.157ZM206.513 168.993C202.321 168.993 199.194 172.49 199.194 176.48C199.194 180.28 202.243 183.925 206.47 183.925C211.651 183.925 213.867 179.21 213.867 176.515C213.867 170.591 208.955 168.993 206.513 168.993Z"
      fill="#089DD9"
    />
    <path
      d="M227.326 187.007H223.359V169.563H220.501V165.954H223.359V158.889H227.326V165.954H230.791V169.563H227.326V187.007Z"
      fill="#089DD9"
    />
    <path
      d="M243.749 187.534C236.663 187.534 232.351 181.645 232.351 176.325C232.351 170.591 237.192 165.384 243.749 165.384C250.15 165.384 255.147 170.401 255.147 176.403C255.147 181.532 250.912 187.534 243.749 187.534ZM243.749 168.993C239.288 168.993 236.317 172.68 236.317 176.438C236.317 180.238 239.331 183.925 243.749 183.925C248.019 183.925 251.18 180.392 251.18 176.403C251.18 172.68 248.209 168.993 243.749 168.993Z"
      fill="#089DD9"
    />
    <path
      d="M273.934 187.007V175.572C273.934 173.94 273.934 169 268.676 169C265.359 169 263.115 171.315 263.115 175.537V187.014H259.148V165.961H262.847V168.205H262.924C265.056 165.391 268.26 165.391 269.1 165.391C272.374 165.391 275.042 166.798 276.567 169.155C277.407 170.52 277.865 172.195 277.901 174.552V187.014H273.934V187.007Z"
      fill="#089DD9"
    />
    <path
      d="M38.831 228.381V216.144H27.2071V228.381H23.0925V200.263H27.2071V212.387H38.831V200.263H42.9456V228.381H38.831Z"
      fill="#089DD9"
    />
    <path
      d="M57.8442 228.908C50.7584 228.908 46.4462 223.019 46.4462 217.699C46.4462 211.965 51.2877 206.758 57.8442 206.758C64.2455 206.758 69.2422 211.775 69.2422 217.777C69.2422 222.906 65.0077 228.908 57.8442 228.908ZM57.8442 210.367C53.3838 210.367 50.4125 214.055 50.4125 217.812C50.4125 221.612 53.4261 225.299 57.8442 225.299C62.1141 225.299 65.2759 221.766 65.2759 217.777C65.2759 214.055 62.3046 210.367 57.8442 210.367Z"
      fill="#089DD9"
    />
    <path
      d="M100.493 228.381V216.679C100.493 214.892 100.268 210.374 95.5036 210.374C90.4363 210.374 90.5139 214.899 90.4716 216.644V228.388H86.5052V216.686C86.5405 214.941 86.4699 213.759 85.63 212.352C84.642 210.832 83.1528 210.374 81.706 210.374C76.4834 210.374 76.4834 215.089 76.4834 216.644V228.388H72.517V207.335H76.1728V209.615H76.2505C77.0127 208.742 78.3819 206.765 82.313 206.765C84.8678 206.765 87.8038 207.982 88.8695 210.297C89.5541 209.235 91.1209 206.765 95.8424 206.765C99.3853 206.765 101.905 208.207 103.274 210.564C104.036 211.93 104.453 213.639 104.453 215.694V228.388H100.493V228.381Z"
      fill="#089DD9"
    />
    <path
      d="M112.421 219.564C113.409 223.631 116.691 225.299 119.358 225.299C122.294 225.299 124.426 223.589 125.378 221.766H129.345C127.326 227.199 122.485 228.908 119.21 228.908C113.225 228.908 108.461 223.779 108.461 217.812C108.461 211.507 113.338 206.758 119.288 206.758C125.004 206.758 129.994 211.395 130.037 217.552C130.037 218.234 129.959 219.297 129.924 219.564H112.421ZM126.063 216.524C125.873 213.449 123.049 210.367 119.238 210.367C115.427 210.367 112.604 213.442 112.414 216.524H126.063Z"
      fill="#089DD9"
    />
    <path
      d="M144.279 228.908C137.193 228.908 132.881 223.019 132.881 217.699C132.881 211.965 137.722 206.758 144.279 206.758C150.68 206.758 155.677 211.775 155.677 217.777C155.677 222.906 151.442 228.908 144.279 228.908ZM144.279 210.367C139.818 210.367 136.847 214.055 136.847 217.812C136.847 221.612 139.861 225.299 144.279 225.299C148.549 225.299 151.71 221.766 151.71 217.777C151.71 214.055 148.739 210.367 144.279 210.367Z"
      fill="#089DD9"
    />
    <path
      d="M178.395 228.381H176.186L171.613 214.209H171.535L166.962 228.381H164.788L156.898 207.328H161.125L165.812 221.007H165.889L170.272 207.328H172.862L177.245 221.007H177.323L181.973 207.328H186.201L178.395 228.381Z"
      fill="#089DD9"
    />
    <path
      d="M204.007 228.381V216.946C204.007 215.314 204.007 210.374 198.749 210.374C195.432 210.374 193.188 212.689 193.188 216.911V228.388H189.222V207.335H192.92V209.579H192.998C195.129 206.765 198.333 206.765 199.173 206.765C202.448 206.765 205.115 208.172 206.64 210.529C207.48 211.894 207.938 213.569 207.974 215.926V228.388H204.007V228.381Z"
      fill="#089DD9"
    />
    <path
      d="M215.935 219.564C216.923 223.631 220.205 225.299 222.872 225.299C225.808 225.299 227.94 223.589 228.893 221.766H232.859C230.84 227.199 225.999 228.908 222.724 228.908C216.739 228.908 211.975 223.779 211.975 217.812C211.975 211.507 216.852 206.758 222.802 206.758C228.518 206.758 233.508 211.395 233.551 217.552C233.551 218.234 233.473 219.297 233.438 219.564H215.935ZM229.584 216.524C229.394 213.449 226.571 210.367 222.759 210.367C218.948 210.367 216.125 213.442 215.935 216.524H229.584Z"
      fill="#089DD9"
    />
    <path
      d="M237.51 228.381V207.328H241.208V209.228H241.286C242.965 206.87 245.668 206.793 246.657 206.758V210.825C246.05 210.825 244.751 211.092 243.798 211.585C242.542 212.267 241.476 213.865 241.476 216.869V228.381H237.51Z"
      fill="#089DD9"
    />
    <path
      d="M258.767 212.915C258.542 211.205 257.511 210.367 256.064 210.367C254.618 210.367 253.587 211.282 253.587 212.612C253.587 214.357 255.112 214.814 256.862 215.349C258.499 215.842 263.228 217.172 263.228 222.189C263.228 225.911 260.638 228.915 256.142 228.915C253.474 228.915 248.675 227.241 248.675 221.471H252.641C252.797 223.828 254.011 225.306 256.227 225.306C258.019 225.306 259.276 224.201 259.276 222.421C259.276 220.556 257.638 219.916 255.577 219.191C253.291 218.354 249.635 217.326 249.635 212.844C249.635 209.727 252.267 206.765 256.269 206.765C258.669 206.765 262.635 208.25 262.748 212.922H258.767V212.915Z"
      fill="#089DD9"
    />
    <path
      d="M281.366 228.381V216.946C281.366 215.314 281.366 210.374 276.108 210.374C272.791 210.374 270.547 212.689 270.547 216.911V228.388H266.58V200.27H270.547V209.579H270.624C272.53 206.765 275.692 206.765 276.531 206.765C279.806 206.765 282.474 208.172 283.998 210.529C284.838 211.894 285.297 213.569 285.332 215.926V228.388H281.366V228.381Z"
      fill="#089DD9"
    />
    <path
      d="M294.281 204.893H290.315V200.256H294.281V204.893ZM294.281 228.381H290.315V207.328H294.281V228.381Z"
      fill="#089DD9"
    />
    <path
      d="M298.629 207.328H302.249V210.557H302.327C304.233 207.82 307.126 206.758 309.836 206.758C317 206.758 320.776 212.492 320.776 218.122C320.776 223.441 316.774 228.915 310.104 228.915C305.686 228.915 303.47 226.671 302.673 225.341H302.595V235.41H298.629V207.328ZM309.568 210.367C305.411 210.367 302.518 213.942 302.518 217.812C302.518 222.069 305.679 225.299 309.681 225.299C313.414 225.299 316.809 222.336 316.809 217.889C316.809 214.132 313.873 210.367 309.568 210.367Z"
      fill="#089DD9"
    />
    <path
      d="M100.656 269.755L91.3538 256.723H91.2761V269.755H87.1615V241.637H95.8918C99.8582 241.637 101.912 242.587 103.401 243.994C105.194 245.704 105.991 248.287 105.991 250.644C105.991 258.088 99.7382 259.271 97.9103 259.271L105.801 269.755H100.656ZM91.2832 256.146H94.1062C95.7859 256.146 101.884 256.069 101.884 250.637C101.884 248.28 100.282 245.395 95.6307 245.395H91.2832V256.146Z"
      fill="#089DD9"
    />
    <path
      d="M112.781 260.938C113.769 265.005 117.051 266.673 119.718 266.673C122.654 266.673 124.786 264.963 125.738 263.141H129.705C127.686 268.573 122.845 270.282 119.57 270.282C113.585 270.282 108.821 265.153 108.821 259.186C108.821 252.881 113.698 248.132 119.648 248.132C125.364 248.132 130.354 252.769 130.396 258.926C130.396 259.608 130.319 260.671 130.284 260.938H112.781ZM126.423 257.898C126.232 254.823 123.409 251.742 119.598 251.742C115.787 251.742 112.964 254.816 112.774 257.898H126.423Z"
      fill="#089DD9"
    />
    <path
      d="M142.656 254.289C142.43 252.579 141.399 251.742 139.952 251.742C138.506 251.742 137.475 252.656 137.475 253.986C137.475 255.731 139 256.189 140.75 256.723C142.387 257.216 147.116 258.546 147.116 263.563C147.116 267.285 144.526 270.289 140.03 270.289C137.362 270.289 132.563 268.615 132.563 262.845H136.53C136.685 265.202 137.899 266.68 140.115 266.68C141.907 266.68 143.164 265.575 143.164 263.795C143.164 261.93 141.526 261.29 139.465 260.565C137.179 259.728 133.523 258.701 133.523 254.218C133.523 251.101 136.155 248.139 140.157 248.139C142.557 248.139 146.523 249.624 146.636 254.296L142.656 254.289Z"
      fill="#089DD9"
    />
    <path
      d="M160.801 270.282C153.715 270.282 149.403 264.393 149.403 259.073C149.403 253.339 154.244 248.132 160.801 248.132C167.202 248.132 172.199 253.149 172.199 259.151C172.192 264.28 167.964 270.282 160.801 270.282ZM160.801 251.742C156.34 251.742 153.369 255.429 153.369 259.186C153.369 262.986 156.383 266.673 160.801 266.673C165.071 266.673 168.232 263.141 168.232 259.151C168.232 255.429 165.254 251.742 160.801 251.742Z"
      fill="#089DD9"
    />
    <path
      d="M180.16 248.702V260.101C180.16 265.308 182.482 266.673 185.418 266.673C188.692 266.673 190.944 264.583 190.944 259.988V248.702H194.91V269.755H191.29V267.475H191.212C190.146 269.297 187.669 270.289 185.001 270.289C182.602 270.289 180.506 269.53 178.981 268.2C177.231 266.68 176.2 264.323 176.2 260.108V248.709H180.16V248.702Z"
      fill="#089DD9"
    />
    <path
      d="M200.013 269.755V248.702H203.711V250.602H203.789C205.468 248.244 208.171 248.167 209.159 248.132V252.199C208.552 252.199 207.254 252.466 206.301 252.959C205.045 253.641 203.979 255.239 203.979 258.243V269.755H200.013Z"
      fill="#089DD9"
    />
    <path
      d="M227.686 255.696C227.036 254.366 225.512 251.742 221.743 251.742C216.633 251.742 214.805 255.999 214.805 259.186C214.805 262.831 217.127 266.673 221.665 266.673C224.637 266.673 226.655 264.963 227.65 262.796H232.068C230.466 267.468 226.352 270.282 221.665 270.282C214.728 270.282 210.839 264.618 210.839 259.186C210.839 253.487 215.031 248.132 221.701 248.132C227.686 248.132 231.264 252.579 232.068 255.696H227.686Z"
      fill="#089DD9"
    />
    <path
      d="M238.738 260.938C239.726 265.005 243.008 266.673 245.676 266.673C248.611 266.673 250.743 264.963 251.696 263.141H255.662C253.644 268.573 248.802 270.282 245.527 270.282C239.542 270.282 234.779 265.153 234.779 259.186C234.779 252.881 239.655 248.132 245.605 248.132C251.322 248.132 256.311 252.769 256.354 258.926C256.354 259.608 256.276 260.671 256.241 260.938H238.738ZM252.387 257.898C252.197 254.823 249.374 251.742 245.563 251.742C241.751 251.742 238.928 254.816 238.738 257.898H252.387Z"
      fill="#089DD9"
    />
    <path
      d="M132.563 290.188C131.723 289.239 129.211 286.234 124.637 286.234C118.766 286.234 114.157 291.061 114.157 296.986C114.157 303.03 119.034 307.892 124.56 307.892C127.531 307.892 130.312 306.45 132.26 304.17H137.327C134.2 309.679 128.907 311.657 124.525 311.657C116.521 311.657 110.042 304.93 110.042 296.95C110.042 288.746 116.676 282.477 124.56 282.477C126.691 282.477 130.121 283.011 133.17 285.214C135.725 287.036 136.791 288.936 137.518 290.188H132.563Z"
      fill="#089DD9"
    />
    <path
      d="M143.841 302.312C144.829 306.379 148.111 308.047 150.779 308.047C153.715 308.047 155.846 306.337 156.799 304.515H160.765C158.747 309.947 153.905 311.657 150.631 311.657C144.646 311.657 139.882 306.527 139.882 300.56C139.882 294.256 144.759 289.506 150.708 289.506C156.425 289.506 161.415 294.143 161.457 300.3C161.457 300.982 161.379 302.045 161.344 302.312H143.841ZM157.484 299.272C157.293 296.198 154.47 293.116 150.659 293.116C146.848 293.116 144.025 296.191 143.834 299.272H157.484Z"
      fill="#089DD9"
    />
    <path
      d="M180.16 311.129V299.695C180.16 298.062 180.16 293.123 174.902 293.123C171.585 293.123 169.34 295.438 169.34 299.659V311.136H165.374V290.083H169.072V292.328H169.15C171.281 289.513 174.485 289.513 175.325 289.513C178.6 289.513 181.268 290.92 182.792 293.277C183.632 294.643 184.091 296.317 184.126 298.674V311.136L180.16 311.129Z"
      fill="#089DD9"
    />
    <path
      d="M193.612 311.129H189.645V293.686H186.787V290.076H189.645V283.011H193.612V290.076H197.077V293.686H193.612V311.129Z"
      fill="#089DD9"
    />
    <path
      d="M202.645 302.312C203.633 306.379 206.915 308.047 209.583 308.047C212.519 308.047 214.65 306.337 215.603 304.515H219.569C217.551 309.947 212.709 311.657 209.435 311.657C203.45 311.657 198.686 306.527 198.686 300.56C198.686 294.256 203.563 289.506 209.512 289.506C215.229 289.506 220.219 294.143 220.261 300.3C220.261 300.982 220.183 302.045 220.148 302.312H202.645ZM216.288 299.272C216.097 296.198 213.274 293.116 209.463 293.116C205.652 293.116 202.829 296.191 202.638 299.272H216.288Z"
      fill="#089DD9"
    />
    <path
      d="M224.22 311.129V290.076H227.919V291.976H227.996C229.676 289.619 232.379 289.541 233.367 289.506V293.573C232.76 293.573 231.461 293.84 230.509 294.333C229.252 295.015 228.187 296.613 228.187 299.617V311.129H224.22Z"
      fill="#089DD9"
    />
  </svg>
</template>
